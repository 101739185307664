<template>
  <div>
    <Progress v-if="loading"/>
    <v-card
    tile
    v-if="!loading"
    >
    <v-card-text class="text-center">
        <v-container>
            <v-row justify="center" align="center">
                <v-col
                    cols="9"
                >

                  <v-col cols="4">
                    <v-select
                    :menu-props="{contentClass: 'dd-list-branch'}"
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go_data()"
                  ></v-select>
                  </v-col>

                </v-col>

                <v-col
                    cols="3"
                    align="right"
                >
                <download-excel :data="data" style="display:inline-block"
                :title="'Report Stock from ' + from + ' to ' + to" :name="'Report Stock from ' + from + ' to ' + to +'.xls'">
                <v-btn color="success" small depressed class="mr-2">
                  <v-icon small>mdi-microsoft-excel</v-icon>
                </v-btn>
                </download-excel>
                          <v-btn color="red" small depressed @click="downloadPdf()">
                        <v-icon small color="white">mdi-file-pdf-outline</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="stock"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
          @click:row="openDetail"
        >

           <template v-slot:item.stock="{ item }">
            {{item.stock}}
          </template>

        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
    </v-card-text>
    </v-card>
    <DetailDialog :data="data_detail" v-if="dialog_detail" v-on:close="openDetail"/>
    <vue-html2pdf
      ref="html2Pdf"
      :paginate-elements-by-height="1400"
      pdf-content-width="1100px"
      pdf-orientation="landscape"
      :filename="`Report Stock form ${from} to ${to}`"
    >
    <templatePDF :headers="['Company','Branch','Product','Uom','Stock','TStock Minimum']"
    :title="`Report Stock from ${from} to ${to}`"
    slot="pdf-content"
     >

    <tr v-for="item in data" :key="item.Document">
      <td>{{item.Branch}}</td>
      <td>{{item.Document}}</td>
      <td>{{item.Product}}</td>
      <td>{{item.Uom}}</td>
      <td>{{item.Stock}}</td>
      <td>{{item.stock_minimum}}</td>
      <td>
        <ul>
          <li v-for="detai in item.Details" :key="detai.product_id"> {{detai.product.name}} = {{detai.qty}}</li>
        </ul>
      </td>
    </tr>
    </templatePDF>
    </vue-html2pdf>
  </div>
</template>

<script>
// @ is an alias to /src
import Mixin from './mixin'
import {mapGetters} from 'vuex'
import downloadExcel from "vue-json-excel";
import VueHtml2pdf from 'vue-html2pdf'
import templatePDF from '../../../components/external/report-pdf'
export default {
  mixins:[Mixin],
  data() {
    return {
      data:[],
      pdf_data:'',
      headers: [
        {
          text: 'Company',
          align: 'start',
          value: 'company.name'
        },
        {
          text: 'Branch',
          value: 'branch.name'
        },
         {
          text: 'Product',
          value: 'product.name'
        },
        {
          text: 'UoM',
          value: 'product.uom.kode'
        },
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Stock Minumum',
          value: 'product.stock_minimum'
        },
      ]
    }
  },
  components: {
    downloadExcel,VueHtml2pdf,templatePDF,
  },
  computed: {
    ...mapGetters({
      stock: 'data/stock',
      branchs: 'data/branchs',
    }),
  },
  methods:{
    async go_data () {
      await this.axios.get('/v1/stock/report?company_id=' + this.user.company_id + `&branch_id=${this.branch_id}&from=${this.from}&to=${this.to}` ,)
        .then(async (ress) => {
          console.log(ress.data.data)
          await this.setData({ data: ress.data.data, table_name: 'stock' })
          this.data = await this.stock.map(x => {
            let value = {
              Company:x.company.name,
              Branch:x.branch.name,
              Product:x.product.name,
              Uom:x.product.uom.kode,
              Stock:x.total_in - x.total_out,
              stock_minimum:x.stock_minimum,
            }

            return value
          })

        })
        .catch(err => console.log(err))
          console.log(this.data)

      this.loading = false
    },
     async downloadPdf() {
      this.dialog_loading = true
      await this.$refs.html2Pdf.generatePdf();
      this.dialog_loading = false
    },
  },
  async created() {
    if (this.stock.length) {
      this.loading = false
    }
  },
  async mounted () {
    await this.go('branch/all','branchs')
    this.branch_id = this.user.branch_id
    await this.go_data()

  }
}
</script>
